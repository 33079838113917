<template>
  <el-dialog
    v-model="showAccountDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-counterparty-account-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-counterparty-account-dialog-header__title">
        {{ dialogTitle }}
      </h4>
    </template>
    <el-form ref="accountFormRef" :model="accountForm" :rules="rules" label-position="top">
      <div class="elv-account-accountType-select-content">
        <el-form-item :label="t('common.platformType')" prop="accountType">
          <el-select
            v-model="accountForm.accountType"
            placeholder=" "
            popper-class="elv-counterparty-account-dialog-popper"
            :class="{
              'elv-account-accountType-short-select': accountForm.accountType !== ''
            }"
            :disabled="props.model === 'edit' || props.accountType !== ''"
            @change="onChangeAccountType"
          >
            <el-option
              v-for="(item, index) in accountTypeOptions"
              :key="index"
              :disabled="item.value === 'ESP'"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="accountForm.accountType !== ''"
          :label="accountLabel"
          class="elv-account-form-item-platform"
          prop="platformId"
        >
          <el-select
            v-model="accountForm.platformId"
            placeholder=" "
            class="elv-account-accountType-short-select"
            popper-class="elv-counterparty-account-dialog-popper"
          >
            <el-option v-for="(item, index) in platformList" :key="index" :label="item.name" :value="item.platformId">
              <div class="elv-counterparty-account-dialog-platform-option">
                <img :src="item.logo" :alt="item.name" />
                <p>{{ item.name }}</p>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <el-form-item v-if="accountForm.accountType !== ''" :label="identityLabel" prop="identity">
        <el-input v-model.trim="accountForm.identity" :placeholder="identityLabel" />
      </el-form-item>
    </el-form>
    <div class="el-dialog__footer">
      <elv-button
        type="primary"
        round
        height="44"
        width="113"
        class="elv-account--dialog-footer__button"
        :loading="submitLoading"
        :disabled="disabledSave"
        @click="onClickConnect"
        >{{ t('button.save') }}</elv-button
      >
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import { transformI18n } from '@/i18n/index'
import { find, pick, isEmpty } from 'lodash-es'
import { useEntityStore } from '@/stores/modules/entity'
import type { FormInstance, FormRules } from 'element-plus'
import { useAccountStore } from '@/stores/modules/accounts'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  accountType: {
    type: String,
    default: ''
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

interface accountFormType {
  identity: string
  platformId: string | string
  accountType: string
}

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const { bankList, chainList, exchangeList, custodyList, paymentList } = storeToRefs(entityStore)

const showPlatform = ref(true)
const submitLoading = ref(false)
const showAccountDialog = ref(false)
const accountFormRef = ref<FormInstance>()

const accountTypeOptions = ref([
  {
    label: t('common.onChain'),
    value: 'CHAIN',
    platformLabel: t('common.chain'),
    identityLabel: t('common.address')
  },
  {
    label: t('common.bank'),
    value: 'BANK',
    platformLabel: t('common.contentTextName', { content: t('common.bank') }),
    identityLabel: t('common.accountNumber')
  },
  {
    label: t('common.exchange'),
    value: 'EXCHANGE',
    platformLabel: t('common.contentTextName', { content: t('common.exchange') }),
    identityLabel: t('common.accountId')
  },
  {
    label: t('common.custody'),
    value: 'CUSTODY',
    platformLabel: t('common.contentTextName', { content: t('common.custody') }),
    identityLabel: t('common.accountId')
  },
  {
    label: t('common.payment'),
    value: 'PAYMENT',
    platformLabel: t('common.contentTextName', { content: t('common.payment') }),
    identityLabel: t('common.accountId')
  }
])

const accountForm: accountFormType = reactive({
  type: 'INDIVIDUAL',
  identity: '',
  platformId: '',
  accountType: ''
})

const rules = reactive<FormRules>({
  platformId: {
    required: true,
    trigger: 'blur',
    message: 'platform is required'
  },
  identity: {
    required: true,
    trigger: 'blur',
    message: 'input is required'
  },
  accountType: {
    required: true,
    trigger: 'blur',
    message: 'Account type is required'
  }
})

const emit = defineEmits(['resetDetail'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const accountLabel = computed(() => {
  return find(accountTypeOptions.value, { value: accountForm.accountType })?.platformLabel
})

const identityLabel = computed(() => {
  return find(accountTypeOptions.value, { value: accountForm.accountType })?.identityLabel
})

const platformList = computed(() => {
  let list: any = []
  switch (accountForm.accountType) {
    case 'CHAIN':
      list = chainList.value
      break
    case 'BANK':
      list = bankList.value
      break
    case 'EXCHANGE':
      list = exchangeList.value
      break
    case 'CUSTODY':
      list = custodyList.value
      break
    case 'PAYMENT':
      list = paymentList.value
      break
    default:
      break
  }
  return list
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const dialogTitle = computed(() => {
  return props.model === 'edit' ? t('button.editAccount') : t('button.addAccount')
})

const disabledSave = computed(() => {
  return (
    (props.model === 'add' && (!accountForm.accountType || !accountForm.platformId || !accountForm.identity)) ||
    (props.model === 'edit' &&
      accountForm.accountType &&
      accountForm.platformId === props.currentData?.platformId &&
      accountForm.identity === props.currentData?.identity)
  )
})

const onChangeAccountType = () => {
  accountForm.platformId = ''
  accountForm.identity = ''
}

// 切换弹窗展示
const onCheckAccountDialog = () => {
  showAccountDialog.value = !showAccountDialog.value
}

const onCloseDialog = () => {
  showPlatform.value = false
  accountFormRef.value?.resetFields()
}

const onClickConnect = async () => {
  if (!accountFormRef.value) return
  await accountFormRef.value.validate(async (valid: boolean) => {
    if (valid && accountStore.counterpartyDetail?.counterpartyId) {
      try {
        submitLoading.value = true
        const params = pick(accountForm, ['platformId', 'identity'])
        if (props.model === 'edit') {
          if (
            ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
            !currentEntityPermission.value?.counterparty?.update
          ) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          await AccountsApi.editCounterpartyContact(
            entityId.value,
            props.currentData?.counterpartyId,
            props.currentData?.contactId,
            params
          )
        } else {
          if (
            ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
            !currentEntityPermission.value?.counterparty?.create
          ) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          await AccountsApi.addCounterpartyContact(
            entityId.value,
            accountStore.counterpartyDetail?.counterpartyId,
            params
          )
        }
        ElMessage.success(t('message.saveSuccess'))
        onCheckAccountDialog()
        emit('resetDetail')
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

defineExpose({ onCheckAccountDialog })

watch(
  () => showAccountDialog.value,
  () => {
    if (showAccountDialog.value && props.model === 'edit' && !isEmpty(props.currentData)) {
      accountForm.accountType = props.accountType
      accountForm.identity = props.currentData.identity
      accountForm.platformId = props.currentData.platformId
    } else if (showAccountDialog.value && props.model === 'add') {
      accountForm.accountType = props.accountType
      accountForm.identity = ''
      accountForm.platformId = ''
    }
  }
)
</script>

<style lang="scss">
.elv-counterparty-account-dialog {
  width: 620px;
  min-height: 254px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-counterparty-account-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-counterparty-account-dialog-content__platform {
      margin: 0 auto 16px;
      display: flex;
      width: 182px;
      height: 80px;
      padding: 0px 15px 0px 17px;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .el-textarea {
      width: 572px;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-select:not(.elv-account-accountType-short-select) {
      width: 572px;
    }

    .elv-account-accountType-short-select {
      .el-input,
      .el-select__wrapper {
        width: 282px;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
        text-transform: capitalize;
      }

      &.elv-form-item__fileType {
        margin-bottom: 8px;
      }

      &.elv-account-form-item-platform {
        .el-form-item__content {
          width: 387px;
          margin-left: 8px;
          justify-content: space-between;
        }

        .el-form-item__label {
          width: 387px;
          margin-left: 8px;
          text-transform: capitalize;
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      .el-select__selected-item {
        .el-tag {
          padding: 0 16px;
          height: 24px;
          border-radius: 40px;
          border: 1px solid #d0d4d9;
          box-sizing: border-box;
          color: #636b75;
          background-color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-counterparty-account-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-account-accountType-select-content {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;

      .el-form-item {
        margin-bottom: 0px;
      }

      .elv-account-date-select {
        box-sizing: border-box;
        height: 44px;
        width: 282px;
        padding: 8px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: relative;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: border, box-shadow;

        &.is-short {
          width: 178px;
        }

        > div {
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #aaafb6;
            margin-bottom: 8px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #0e0f11;
          }
        }

        &:hover,
        &.is-focus {
          cursor: pointer;
          border: 1px solid #5e85eb;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          svg {
            fill: #5e85eb;
          }
        }

        svg {
          position: absolute;
          right: 12px;
          bottom: 12px;
          fill: #838d95;
          transition: transform 0.3s;
        }

        .elv-account-date-time-value {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .elv-account-date-scope {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-counterparty-account-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    .elv-counterparty-account-dialog-platform-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
