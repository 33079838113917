<template>
  <el-dialog
    v-model="showInformationDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-information-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-information-dialog-header__title">
        {{ dialogTitle }}
      </h4>
    </template>
    <el-form ref="accountFormRef" :model="accountForm" :rules="rules" label-position="top">
      <el-form-item :label="t('common.name')" prop="name">
        <el-input v-model="accountForm.name" placeholder="" />
      </el-form-item>
      <el-form-item :label="t('common.code')" prop="code">
        <el-input v-model="accountForm.code" placeholder="" />
      </el-form-item>
    </el-form>
    <div class="el-dialog__footer">
      <elv-button
        type="primary"
        round
        height="44"
        width="113"
        class="elv-information--dialog-footer__button"
        :loading="submitLoading"
        :disabled="disabledSave"
        @click="onClickConnect"
        >{{ t('button.save') }}</elv-button
      >
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import type { FormInstance, FormRules } from 'element-plus'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const submitLoading = ref(false)
const showInformationDialog = ref(false)
const accountFormRef = ref<FormInstance>()

const accountForm = reactive({
  name: '',
  code: ''
})

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Name is required'
  },
  code: {
    required: true,
    trigger: 'blur',
    message: 'Code is required'
  }
})
const emit = defineEmits(['resetDetail'])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const dialogTitle = computed(() => {
  return props.model === 'edit' ? t('title.editContactInformation') : t('title.addContactInformation')
})

const disabledSave = computed(() => {
  return (
    (props.model === 'add' && (!accountForm.code || !accountForm.name)) ||
    (props.model === 'edit' && !accountForm.code && !accountForm.name)
  )
})

// 切换弹窗展示
const onCheckInformationDialog = () => {
  showInformationDialog.value = !showInformationDialog.value
}

const onCloseDialog = () => {
  accountFormRef.value?.resetFields()
}

const onClickConnect = async () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (!accountFormRef.value) return
  await accountFormRef.value.validate(async (valid: boolean) => {
    if (valid && accountStore.counterpartyDetail?.counterpartyId) {
      try {
        submitLoading.value = true
        if (props.model === 'add')
          await AccountsApi.addCounterpartyIdentificationCode(
            entityId.value,
            accountStore.counterpartyDetail?.counterpartyId,
            accountForm
          )
        else if (props.model === 'edit') {
          await AccountsApi.editCounterpartyIdentificationCode(
            entityId.value,
            accountStore.counterpartyDetail?.counterpartyId,
            props.currentData?.counterpartyIdentificationId,
            accountForm
          )
        }
        ElMessage.success(t('message.saveSuccess'))
        emit('resetDetail')
        onCheckInformationDialog()
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

defineExpose({ onCheckInformationDialog })

watch(
  () => showInformationDialog.value,
  () => {
    if (props.model === 'edit' && showInformationDialog.value && !isEmpty(props.currentData)) {
      accountForm.name = props.currentData?.name
      accountForm.code = props.currentData?.code
    }
  }
)
</script>

<style lang="scss">
.elv-information-dialog {
  width: 620px;
  min-height: 254px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-information-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-information-dialog-content__platform {
      margin: 0 auto 16px;
      display: flex;
      width: 182px;
      height: 80px;
      padding: 0px 15px 0px 17px;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .el-input {
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      width: 572px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      .el-form-item__label {
        text-transform: capitalize;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      &.elv-form-item__fileType {
        margin-bottom: 8px;
      }

      &.elv-information-form-item-platform {
        .el-form-item__content {
          width: 387px;
          margin-left: 8px;
          justify-content: space-between;
        }

        .el-form-item__label {
          width: 387px;
          margin-left: 8px;
          text-transform: capitalize;
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      .el-select__selected-item {
        .el-tag {
          padding: 0 16px;
          height: 24px;
          border-radius: 40px;
          border: 1px solid #d0d4d9;
          box-sizing: border-box;
          color: #636b75;
          background-color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-information-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-information-contactType-select-content {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;

      .el-form-item {
        margin-bottom: 0px;
      }

      .elv-information-date-select {
        box-sizing: border-box;
        height: 44px;
        width: 282px;
        padding: 8px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: relative;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: border, box-shadow;

        &.is-short {
          width: 178px;
        }

        > div {
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #aaafb6;
            margin-bottom: 8px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #0e0f11;
          }
        }

        &:hover,
        &.is-focus {
          cursor: pointer;
          border: 1px solid #5e85eb;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          svg {
            fill: #5e85eb;
          }
        }

        svg {
          position: absolute;
          right: 12px;
          bottom: 12px;
          fill: #838d95;
          transition: transform 0.3s;
        }

        .elv-information-date-time-value {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .elv-information-date-scope {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-information-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    .elv-information-dialog-platform-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
